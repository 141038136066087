import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Web3ReactProvider } from '@web3-react/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { createRoot } from 'react-dom/client'
import { Persistor, store } from './redux/store'
import reportWebVitals from './reportWebVitals'
import './fonts/indigo-regular.otf'
import './index.css'
import axios from 'axios'
import { App } from './App'
import { theme } from './const/theme'
import '@fontsource/poppins'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ModalProvider } from './contexts/modalContext'
import ReactGA from 'react-ga4'

import {
  hooks as metaMaskHooks,
  metaMask,
} from './helpers/connectors/metamaskConnector'
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from './helpers/connectors/walletConnectV2Connector'

ReactGA.initialize('G-WYVQTWQPHG')

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
]

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Web3ReactProvider connectors={connectors}>
          <PersistGate loading={<div>Loading...</div>} persistor={Persistor}>
            <BrowserRouter>
              <ModalProvider>
                <App />
              </ModalProvider>
            </BrowserRouter>
          </PersistGate>
        </Web3ReactProvider>
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>
)

reportWebVitals()
